import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IntermediateService } from './firebase/intermediate.service';
@Injectable()
export class LoadingService {
    private _isLoading = new BehaviorSubject<boolean>(false); // ? Para cuando se necesite un spinner general
    private _isLoadingSpinner = new BehaviorSubject<boolean>(false); // ? Para cuando se necesite un spinner para la red

    constructor(private intermediateService: IntermediateService) {}

    get isLoading() {
        return this._isLoading.asObservable();
    }

    get isLoadingSpinner() {
        return this._isLoadingSpinner.asObservable();
    }

    loadingShow() {
        this._isLoading.next(true);
    }

    loadingHide() {
        setTimeout(() => {
            this._isLoading.next(false);
        }, 500);
    }

    loadingShowSpinner() {
        this._isLoadingSpinner.next(true);
    }

    loadingHideSpinner() {
        setTimeout(() => {
            this._isLoadingSpinner.next(false);
        }, 300);
    }
}
