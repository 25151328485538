import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthentication, User } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@environment/environment';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@services/loading.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { initializeApp } from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntermediateService } from './intermediate.service';

@Injectable()
export class AuthenticationService {
    currentUserSubject = new BehaviorSubject<User | null>(null);

    constructor(
        private intermediateService: IntermediateService,
        private router: Router,
        private sweetAlertsService: SweetAlertsService,
        private loadingService: LoadingService,
        private translateService: TranslateService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.observeAuthState();
    }

    initializeFirebase(): void {
        const firebaseConfig = environment.firebase;
        initializeApp(firebaseConfig);
    }

    private observeAuthState(): void {
        FirebaseAuthentication.addListener('authStateChange', async (user) => {
            this.currentUserSubject.next(user.user);
            await this.storeCurrentUser(user.user); // Guarda el usuario en Preferences
            if (user.user) {
                await this.handleUserSignIn(user.user);
            }
        });

        this.getCurrentUser().then((user) => {
            this.currentUserSubject.next(user);
            this.storeCurrentUser(user); // Guarda el usuario en Preferences
        });
    }

    private async storeCurrentUser(user: User | null): Promise<void> {
        if (isPlatformBrowser(this.platformId)) {
            if (user) {
                await Preferences.set({
                    key: 'currentUser',
                    value: JSON.stringify(user),
                });
            }
        }
    }

    private async removeCurrentUserLocalStorage(): Promise<void> {
        if (isPlatformBrowser(this.platformId)) {
            await Preferences.remove({ key: 'currentUser' });
        }
    }

    get currentUser$(): Observable<User | null> {
        return this.currentUserSubject.asObservable();
    }

    async getCurrentUser() {
        const result = await FirebaseAuthentication.getCurrentUser();
        return result.user;
    }

    async waitForAuthToken(maxAttempts: number = 30, delay: number = 100): Promise<string | null> {
        let token: string | null = null;
        let attempts = 0;

        while (!token && attempts < maxAttempts) {
            token = await this.getIdToken();
            if (!token) {
                attempts++;
                await new Promise((resolve) => setTimeout(resolve, delay)); // Espera antes de intentar de nuevo
            }
        }
        return token;
    }

    private async handleUserSignIn(user: User) {
        const userData = await this.intermediateService.userDataService.getDataUser();
        if (userData && userData.uid === user.uid) {
            this.intermediateService.userDataService.updateUserDataSubject(userData);
            this.checkInitialDataUser(userData);
        } else {
            await this.intermediateService.userDataService.loadUserData();
            const newUserdata = await this.intermediateService.userDataService.getDataUser();
            this.checkInitialDataUser(newUserdata);
        }
    }

    async logout(): Promise<void> {
        console.log('logout');
        try {
            this.loadingService.loadingShow();
            if (this.isAuthenticated()) {
                await FirebaseAuthentication.signOut();
            }
            this.currentUserSubject.next(null);
            await this.storeCurrentUser(null); // Elimina el usuario de Preferences
            this.intermediateService.userDataService.userDataSubject.next(null);
            this.intermediateService.userDataService.updateUserDataSubject(null);
            // remove user data from local storage
            await this.removeCurrentUserLocalStorage();
            await this.router.navigate(['/home']);
        } catch (error) {
            console.log('Error en logout:', error);
            this.showErrorAlert('SHARED.ERROR', 'Error in logout');
        } finally {
            this.loadingService.loadingHide();
        }
    }

    async getIdToken(): Promise<string | null> {
        try {
            const currentUser = await this.getCurrentUser();
            if (!currentUser) {
                return null;
            }
            const result = await FirebaseAuthentication.getIdToken();
            return result.token;
        } catch (error) {
            console.log('Error in getIdToken:', error);
            return null;
        }
    }

    async signUp(email: string, password: string): Promise<void> {
        this.loadingService.loadingShow();

        await FirebaseAuthentication.createUserWithEmailAndPassword({ email, password })
            .then(async (result: any) => {
                if (!result || !result.user) throw new Error('Error al crear usuario');
                await this.sendVerificationMail();
                await this.setUserData(result.user as User);
            })
            .catch((error: any) => {
                const messageError = this.handleAuthError(error);
                this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), messageError, 'dark');
                this.loadingService.loadingHide();
            });
    }

    async signIn(email: string, password: string): Promise<void> {
        this.loadingService.loadingShow();
        await FirebaseAuthentication.signInWithEmailAndPassword({ email, password })
            .then(async (result: any) => {
                if (!result || !result.user) throw new Error('Error al iniciar sesión');
                await this.setUserData(result.user as User);
                await this.router.navigate(['/dashboard/chat']);
            })
            .catch((error: any) => {
                const messageError = this.handleAuthError(error);
                this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), messageError, 'dark');
                this.loadingService.loadingHide();
            });
    }

    async sendVerificationMail(): Promise<void> {
        try {
            const currentUser = await this.getCurrentUser();
            if (!currentUser) {
                return;
            }
            await FirebaseAuthentication.sendEmailVerification();

            await this.router.navigate(['/home/verify-email-address']);
        } catch (error) {
            console.log('Error en sendVerificationMail:', error);
            this.showErrorAlert('SHARED.ERROR', 'AUTHENTICATION.ERROR_SENDING_EMAIL');
        }
    }

    async forgotPassword(email: string): Promise<void> {
        this.loadingService.loadingShow();
        await FirebaseAuthentication.sendPasswordResetEmail({ email })
            .then(() => {
                this.sweetAlertsService.show('success', this.translateService.instant('SHARED.SUCCESS'), this.translateService.instant('AUTHENTICATION.PASSWORD_RESET_EMAIL_SENT'), 'dark');
            })
            .catch((error: any) => {
                console.log('Error: ', error);
                this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), this.translateService.instant('AUTHENTICATION.ERROR_SENDING_EMAIL'), 'dark');
            })
            .finally(() => {
                this.loadingService.loadingHide();
            });
    }

    async setUserData(user: User): Promise<void> {
        try {
            let userData = await this.intermediateService.userDataService.getDataUser();

            if (!userData || userData.uid !== user.uid) {
                // Create user data
                const dataCreationUser = await this.intermediateService.userDataService.addUser(user);
                if (!dataCreationUser) return Promise.reject('Error creating user data');
                userData = dataCreationUser;
            } else {
                // Get user data existing
                userData = await this.intermediateService.userDataService.verifyAndUpdateUserData();
            }
            this.intermediateService.userDataService.updateUserDataSubject(userData);
            this.checkInitialDataUser(userData);
        } catch (error) {
            console.log('Error in setUserData:', error);
            this.showErrorAlert('SHARED.ERROR', 'AUTHENTICATION.COULD_NOT_SET_USER_DATA');
        }
    }

    private checkInitialDataUser(user: any) {
        if (user && user.avatarSelected === '0') {
            this.router.navigate(['/dashboard/select-avatar']);
        }
    }

    public async setLanguageCode(languageCode: string): Promise<void> {
        await FirebaseAuthentication.setLanguageCode({ languageCode });
    }

    public async signInWithApple() {
        return await FirebaseAuthentication.signInWithApple();
    }

    async signInWithGoogle() {
        return await FirebaseAuthentication.signInWithGoogle({ mode: 'popup' });
    }

    async signInAnonymously() {
        return await FirebaseAuthentication.signInAnonymously();
    }

    public async useAppLanguage(): Promise<void> {
        await FirebaseAuthentication.useAppLanguage();
    }

    async deleteAccount(): Promise<void> {
        this.loadingService.loadingShow();
        try {
            const idToken = await this.getIdToken();
            if (!idToken) throw new Error('Please log in again to delete your account.');
            const userData = await this.intermediateService.userDataService.getDataUser();
            if (userData) {
                await this.intermediateService.userApiService.deleteAccountApi();

                await FirebaseAuthentication.deleteUser();
                await this.logout();
            } else {
                throw new Error('Account deletion failed: User data is not available.');
            }
        } catch (error) {
            console.log('Error:', error);
            this.showErrorAlert('SHARED.ERROR', 'AUTHENTICATION.ACCOUNT_DELETION_FAILED');
            return Promise.reject(error);
        } finally {
            this.loadingService.loadingHide();
        }
    }

    private handleAuthError(error: any): string {
        switch (error.code) {
            case 'auth/email-already-in-use':
                return this.translateService.instant('AUTHENTICATION.EMAIL_IS_ALREADY_USE');
            case 'auth/invalid-email':
                return this.translateService.instant('AUTHENTICATION.EMAIL_INVALID');
            case 'auth/operation-not-allowed':
                return this.translateService.instant('AUTHENTICATION.LOGIN_NOT_ENABLED');
            case 'auth/weak-password':
                return this.translateService.instant('AUTHENTICATION.PASSWORD_TOO_WEAK');
            case 'auth/user-disabled':
                return this.translateService.instant('AUTHENTICATION.USER_IS_DISABLED');
            case 'auth/user-not-found':
                return this.translateService.instant('AUTHENTICATION.USER_NOT_FOUND');
            case 'auth/wrong-password':
                return this.translateService.instant('AUTHENTICATION.WRONG_PASSWORD');
            default:
                return this.translateService.instant('AUTHENTICATION.ERROR_REGISTER');
        }
    }

    private showErrorAlert(titleKey: string, messageKey: string): void {
        this.sweetAlertsService.show('error', this.translateService.instant(titleKey), this.translateService.instant(messageKey), 'dark');
    }

    isAuthenticated(): boolean {
        return this.currentUserSubject.getValue() !== null;
    }
}
