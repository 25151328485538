import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor, PluginResultError } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { LoadingService } from '@services/loading.service';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';

@Injectable()
export class StatusBarService {
    public androidStatusBarColors: Record<string, { color: string }> = {
        '/dashboard/tokens-purchase': { color: '#201f2f' },
        '/dashboard/premium': { color: '#ff9e81' },
        default: { color: '#fafcff' },
    };

    public statusBarColorSubject = new BehaviorSubject<string>(this.androidStatusBarColors['default'].color);

    private readonly platformId = inject(PLATFORM_ID);

    constructor(
        private router: Router,
        private platform: Platform,
        private loadingService: LoadingService,
    ) {
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    distinctUntilChanged((prev, curr) => (prev as NavigationEnd).urlAfterRedirects === (curr as NavigationEnd).urlAfterRedirects),
                )
                .subscribe((event) => this.updateStatusBarBasedOnRoute((event as NavigationEnd).urlAfterRedirects));

            this.loadingService.isLoading.pipe(distinctUntilChanged()).subscribe(async (loading) => {
                try {
                    if (loading) {
                        this.statusBarColorSubject.next('#ff9e81');
                    } else {
                        const url = this.router.url;
                        const color = this.getStatusBarColorForRoute(url);
                        this.statusBarColorSubject.next(color);
                    }
                } catch (error) {
                    this.handleStatusBarError(error as PluginResultError);
                }
            });

            this.statusBarColorSubject.pipe(distinctUntilChanged()).subscribe(async (color) => {
                try {
                    if (this.platform.is('hybrid') && Capacitor.isPluginAvailable('StatusBar')) {
                        await StatusBar.setBackgroundColor({ color });
                        await StatusBar.setOverlaysWebView({ overlay: color === this.androidStatusBarColors['default'].color });
                    } else {
                        console.warn('StatusBar plugin is not available or not on a hybrid platform.');
                    }
                } catch (error) {
                    this.handleStatusBarError(error as PluginResultError);
                }
            });
        }
    }

    async initStatusBar() {
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            await this.platform.ready();
            if (this.platform.is('hybrid')) {
                this.setupStatusBar();
            }
        }
    }

    private setupStatusBar() {
        if (this.platform.is('android')) {
            this.initializeAndroidStatusBar();
        } else if (this.platform.is('ios')) {
            this.initializeIosStatusBar();
        }
    }

    private async initializeIosStatusBar() {
        console.log('INITIALIZING IOS STATUS BAR');
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            try {
                await StatusBar.setOverlaysWebView({ overlay: false });
            } catch (error) {
                this.handleStatusBarError(error as PluginResultError);
            }
        }
    }

    private async initializeAndroidStatusBar() {
        console.log('INITIALIZING ANDROID STATUS BAR');
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            try {
                await StatusBar.setOverlaysWebView({ overlay: true });
            } catch (error) {
                this.handleStatusBarError(error as PluginResultError);
            }
        }
    }

    private async updateStatusBarBasedOnRoute(url: string) {
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            const { color } = this.getStatusBarStyleAndColor(url);
            this.statusBarColorSubject.next(color);
        }
    }

    private getStatusBarStyleAndColor(url: string): any {
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            for (const route of Object.keys(this.androidStatusBarColors)) {
                if (url.includes(route)) {
                    return this.androidStatusBarColors[route];
                }
            }
            return this.androidStatusBarColors['default'];
        }
    }

    getStatusBarColorForRoute(url: string): any {
        if (Capacitor.isPluginAvailable('StatusBar') && isPlatformBrowser(this.platformId)) {
            const { color } = this.getStatusBarStyleAndColor(url);
            return color;
        }
    }

    private handleStatusBarError(error: PluginResultError) {
        console.error('StatusBar Error:', error.message);
    }
}
