import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IntermediateService } from '@services/firebase/intermediate.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient,
        private intermediateService: IntermediateService,
    ) {}

    private async createAuthHeaders(): Promise<HttpHeaders> {
        const token = await this.intermediateService.authenticationService.getIdToken();
        if (!token) {
            console.log('No token found');
            return new HttpHeaders();
        }
        return new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
    }

    async postRequest<T>(url: string, body: any): Promise<T> {
        try {
            const headers = await this.createAuthHeaders();
            return await firstValueFrom(this.http.post<T>(`${environment.pathBackend}/${url}`, body, { headers }));
        } catch (error) {
            console.error(`Error in postRequest - url: ${url}, error:`, error);
            throw error;
        }
    }

    async getRequest<T>(url: string): Promise<T> {
        try {
            const headers = await this.createAuthHeaders();
            return await firstValueFrom(this.http.get<T>(`${environment.pathBackend}/${url}`, { headers }));
        } catch (error) {
            console.error(`Error in getRequest - url: ${url}, error:`, error);
            throw error;
        }
    }

    async createWebSocket(url: string): Promise<WebSocket> {
        const token = await this.intermediateService.authenticationService.getIdToken();
        if (!token) {
            throw new Error('No token found');
        }
        const wsUrl = `${environment.wsBackend}/${url}`;
        const ws = new WebSocket(wsUrl);

        return new Promise((resolve, reject) => {
            ws.onopen = () => {
                console.log('WebSocket connection opened');
                ws.send(JSON.stringify({ type: 'auth', token }));
                resolve(ws);
            };
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                reject(error);
            };
            ws.onclose = (event) => {
                if (event.wasClean) {
                    console.log('WebSocket connection closed cleanly');
                } else {
                    console.error('WebSocket connection closed unexpectedly', event);
                    // Optional: Reconnection logic
                }
            };
            ws.onmessage = (event) => {
                console.log('Audio data received:', event.data);
            };
        });
    }
}
