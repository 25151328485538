import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class SweetAlertsService {
    show(icon: any, title: string, text: string, theme?: any) {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            background: '#323130',
            color: '#fff',
            confirmButtonColor: '#885beff2',
            cancelButtonColor: '#dcdcdc',
        });
    }
}
