export const environment = {
    production: true,
    enableLogs: false, // Deshabilitar logs en producción
    firebase: {
        apiKey: 'AIzaSyC0dNDb-Hzd3Y32e7NYZreh0XXZTen4R7w',
        authDomain: 'dpupps-5d6c5.firebaseapp.com',
        projectId: 'dpupps-5d6c5',
        storageBucket: 'dpupps-5d6c5.appspot.com',
        messagingSenderId: '267141442428',
        appId: '1:267141442428:web:506dcf285cb1a136d412f9',
        measurementId: 'G-RJ1EQ6RNGG',
    },
    pathBackend: 'https://backend.mirailab.io',
    wsBackend: 'wss://backend.mirailab.io',
    executionMode: {
        live: true, // true: webview, false: compilated
        path: 'https://mirailab.io', // Production
    },
    vapiApiKey: 'cd5ff1f1-ecc4-4c4a-8221-bd6cbaee3ad1',
};
