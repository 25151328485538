import { Injectable, Injector } from '@angular/core';
import { UserData } from '@models/User';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class UserApiService {
    private apiService: ApiService;

    constructor(private injector: Injector) {
        this.apiService = this.injector.get(ApiService);
    }

    // Get Data User
    async getDataUserApi(): Promise<UserData> {
        return await this.apiService.getRequest<UserData>('users/user');
    }

    // Add a user
    addUserApi(userData: UserData, language: string) {
        const body = { userData, language };

        return this.apiService.postRequest<UserData>('users/user/add', body);
    }

    // Update a user
    async updateUserApi(data: any) {
        return await this.apiService.postRequest<UserData>('users/user/update/', data);
    }

    async deleteAccountApi() {
        return await this.apiService.postRequest('users/user/disable', {});
    }

    async checkFieldsUserExistsAndUpdate(language: string) {
        const body = { language };
        return await this.apiService.postRequest('users/user/check-fields', body);
    }
}
