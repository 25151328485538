import { Injectable, Injector } from '@angular/core';
import { UserData } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from '@services/api/apis/user-api.service';
import { LoadingService } from '@services/loading.service';
import { ToolsService } from '@services/settings/tools.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { differenceInHours } from 'date-fns';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    userDataSubject = new BehaviorSubject<UserData | null>(null);
    private initialLoadComplete = new BehaviorSubject<boolean>(false);

    private userApiService: UserApiService;

    private authenticationService: AuthenticationService;

    constructor(
        private injector: Injector,
        private toolsService: ToolsService,
        private loadingService: LoadingService,
        private sweetAlertsService: SweetAlertsService,
        private translateService: TranslateService,
    ) {
        this.userApiService = this.injector.get(UserApiService);
        this.authenticationService = this.injector.get(AuthenticationService);
    }

    async initUserData() {
        const userData = await this.checkUserDataLoaded();
        if (userData) {
            await this.verifyAndUpdateUserData();
        }
        this.initialLoadComplete.next(true);
    }

    get userData$(): Observable<UserData | null> {
        return this.userDataSubject.asObservable();
    }

    get isInitialLoadComplete$(): Observable<boolean> {
        return this.initialLoadComplete.asObservable();
    }

    updateUserDataSubject(newUserData: UserData | null) {
        this.userDataSubject.next(newUserData);
    }

    async loadUserData() {
        if (!this.authenticationService.isAuthenticated()) return {} as UserData;

        this.loadingService.loadingShow();
        try {
            const user = this.authenticationService.currentUserSubject.getValue();
            if (!user) throw new Error('User not authenticated');

            this.initialLoadComplete.next(true);

            const userData = await this.userApiService.getDataUserApi();
            this.userDataSubject.next(userData);
            this.loadingService.loadingHide();
            return userData;
        } catch (error) {
            this.loadingService.loadingHide();
            console.log('Error loading user data:', error);
            return null;
        }
    }

    async checkUserDataLoaded() {
        const userData = await firstValueFrom(this.userData$);
        if (!userData) {
            try {
                const fetchedUserData = await this.getDataUser();
                if (fetchedUserData) {
                    this.updateUserDataSubject(fetchedUserData);
                }
                return fetchedUserData || ({} as UserData);
            } catch (error: any) {
                if (error.status === 401) {
                    console.log('User is not authenticated');
                    this.authenticationService.logout();
                } else {
                    console.log('Erro loading user data');
                }
            }
        }
        return userData || ({} as UserData);
    }

    async verifyAndUpdateUserData(): Promise<any> {
        const language = await this.toolsService.detectLanguage();
        const userDataRes: any = await this.userApiService.checkFieldsUserExistsAndUpdate(language);

        if (userDataRes && userDataRes.uid) {
            this.updateUserDataSubject(userDataRes);
            return userDataRes;
        }

        return null;
    }

    async checkIP() {
        try {
            const ip = await this.toolsService.getIP();
            if (ip) {
                const userData = await this.checkUserDataLoaded();
                if (userData) {
                    await this.updateDataUser({ ...userData, ip });
                }
            }
        } catch (error) {
            console.log('Failed to get IP:', error);
            return false;
        }

        return false;
    }

    async updateFreeAdsLastUse(tokens: number) {
        const user = await this.checkUserDataLoaded();
        if (user) {
            const newData = { ...user, freeAdsLastUse: new Date(), tokens: user.tokens + tokens };
            this.updateUserDataSubject(newData);
        }

        return true;
    }

    async checkResetDailyMessages(): Promise<boolean> {
        try {
            const user = await this.checkUserDataLoaded();
            if (user) {
                const lastDate: Date = user.dailyMessagesLastDate;
                const today = new Date();
                const diffHours = differenceInHours(today, lastDate);

                if (diffHours >= 24) {
                    const newData = {
                        ...user,
                        dailyMessages: 0,
                        dailyMessagesLastDate: new Date(),
                    };

                    this.updateUserDataSubject(newData);

                    return true;
                }
            }

            return false;
        } catch (error) {
            console.log('Error to reset daily messages:', error);
            return false;
        }
    }

    async increaseDailyMessages() {
        const user = await this.checkUserDataLoaded();
        if (user) {
            const newData = {
                ...user,
                dailyMessages: user.dailyMessages + 1,
            };

            this.updateUserDataSubject(newData);
        }

        return true;
    }

    async getDataUser(): Promise<UserData> {
        if (!this.authenticationService.isAuthenticated()) return {} as UserData;

        try {
            const userData = await this.userApiService.getDataUserApi();
            if (userData.accountDisabled === true) {
                this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), this.translateService.instant('AUTHENTICATION.ACCOUNT_DISABLED'), 'dark');

                this.authenticationService.logout();
            }
            return userData;
        } catch (error) {
            console.log('Error fetching user data:', error);
            return {} as UserData;
        }
    }

    async updateDataUser(data: any): Promise<UserData | null> {
        try {
            if (!this.authenticationService.isAuthenticated()) return null;
            return await this.userApiService.updateUserApi(data);
        } catch (error) {
            console.log('Error updating user data:', error);
            return null;
        }
    }

    async addUser(userData: any): Promise<UserData | null> {
        if (!this.authenticationService.isAuthenticated()) throw new Error('User not authenticated');
        const language = await this.toolsService.detectLanguage();

        return await this.userApiService.addUserApi(userData, language);
    }

    async checkAndUpdateUserData() {
        // Espera 1 segundo
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Obtén los datos del usuario
        const fetchedUserData = await this.getDataUser();

        // Actualiza los datos del usuario si se obtuvieron correctamente
        if (fetchedUserData) {
            this.updateUserDataSubject(fetchedUserData);
        }
    }
}
