import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { LoadingService } from '@services/loading.service';
import iso6391 from 'iso-639-1';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToolsService {
    backPressedOnce = false;
    private keyboardOpenSubject = new BehaviorSubject<boolean>(false);
    keyboardOpen$ = this.keyboardOpenSubject.asObservable();
    keyboardOpen = false;
    keyboardHeight: number = 0;

    private readonly platformId = inject(PLATFORM_ID);

    constructor(
        private router: Router,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private ngZone: NgZone,
        private http: HttpClient,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.setupBackButtonListener();
            this.setupAppLifecycleListener();
            this.monitorKeyboard();
        }
    }

    async detectDevice(): Promise<boolean> {
        if (isPlatformBrowser(this.platformId)) {
            const info = await Device.getInfo();
            return info.operatingSystem === 'ios';
        }

        return false;
    }

    private setupAppLifecycleListener() {
        if (isPlatformBrowser(this.platformId)) {
            App.addListener('appStateChange', this.handleAppStateChange.bind(this));
        }
    }

    private handleAppStateChange(state: any) {
        console.log('App state changed. Is active?', state.isActive);
        if (!state.isActive) {
            this.loadingService.loadingHide();
        }
    }

    private setupBackButtonListener() {
        if (isPlatformBrowser(this.platformId)) {
            App.addListener('backButton', this.handleBackButtonPress.bind(this));
        }
    }

    handleBackButtonPress() {
        if (isPlatformBrowser(this.platformId)) {
            App.addListener('backButton', () => {
                const currentUrl = this.router.url;

                if (currentUrl === '/dashboard/chat') {
                    if (!this.backPressedOnce) {
                        this.backPressedOnce = true;

                        this.messageService.add({
                            severity: 'info',
                            summary: 'Info',
                            detail: 'Press again to exit',
                        });

                        setTimeout(() => {
                            this.backPressedOnce = false;
                        }, 2000);
                    } else {
                        App.exitApp();
                    }
                } else {
                    if (this.router.url !== '/dashboard/chat') {
                        if (this.router.navigated) {
                            this.router.navigate(['/dashboard/chat']);
                        } else {
                            this.router.navigate([this.router.url]);
                        }
                    }
                }
            });
        }
    }

    async detectLanguage(): Promise<string> {
        if (isPlatformBrowser(this.platformId)) {
            const langCode = await Device.getLanguageCode();
            return iso6391.getName(langCode.value) || 'English';
        }

        return '';
    }

    async getIP(): Promise<string> {
        try {
            const response = await this.http.get<any>('https://api.ipify.org?format=json').toPromise();
            return response.ip;
        } catch (error) {
            console.log('Error fetching IP:', error);
            throw new Error('Error fetching IP: ' + error);
        }
    }

    monitorAppLifecycle() {
        if (isPlatformBrowser(this.platformId)) {
            App.addListener('appStateChange', (state: any) => {
                console.log('App state changed. Is active?', state.isActive);
                // Si la aplicación está en segundo plano
                if (!state.isActive) {
                    console.log('App is in background');
                    // FirebasePerformance.stopTrace({ traceName: 'app_lifecycle' });
                }

                if (state.isActive) {
                    console.log('App is in foreground');
                    this.loadingService.loadingHide();
                    // FirebasePerformance.startTrace({ traceName: 'app_lifecycle' });
                }
            });
        }
    }

    private monitorKeyboard() {
        if (isPlatformBrowser(this.platformId)) {
            Device.getInfo().then((info) => {
                if (info.platform === 'android' || info.platform === 'ios') {
                    Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
                        this.keyboardHeight = info.keyboardHeight;
                        this.ngZone.run(() => {
                            this.keyboardOpenSubject.next(true);
                            this.keyboardOpen = true;
                        });
                    });

                    Keyboard.addListener('keyboardWillHide', () => {
                        this.ngZone.run(() => {
                            this.keyboardOpenSubject.next(false);
                            this.keyboardOpen = false;
                        });
                    });
                }
            });
        }
    }

    soundNewMessage(file: string) {
        const audio = new Audio();
        audio.src = '../../../assets/sounds/' + file + '.mp3';
        audio.load();
        audio.play();
    }

    async getPlatform() {
        // `android`, `ios`, or `web`
        if (isPlatformBrowser(this.platformId)) {
            return Capacitor.getPlatform();
        } else {
            console.log('Platform is a web');
            return 'web';
        }
    }
}
