import { Injectable, Injector } from '@angular/core';
import { UserApiService } from '@services/api/apis/user-api.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { ToolsService } from '@services/settings/tools.service';
import { StatusBarService } from '@services/shared/statusBar.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class IntermediateService {
    constructor(private injector: Injector) {}

    private _userApiService: UserApiService | null = null;
    private _userDataService: UserDataService | null = null;
    private _authenticationService: AuthenticationService | null = null;
    private _toolsService: ToolsService | null = null;
    private _statusBarService: StatusBarService | null = null;

    get userApiService(): UserApiService {
        if (!this._userApiService) {
            this._userApiService = this.injector.get(UserApiService);
        }
        return this._userApiService;
    }

    get userDataService(): UserDataService {
        if (!this._userDataService) {
            this._userDataService = this.injector.get(UserDataService);
        }
        return this._userDataService;
    }

    get authenticationService(): AuthenticationService {
        if (!this._authenticationService) {
            this._authenticationService = this.injector.get(AuthenticationService);
        }
        return this._authenticationService;
    }

    get toolsService(): ToolsService {
        if (!this._toolsService) {
            this._toolsService = this.injector.get(ToolsService);
        }
        return this._toolsService;
    }

    get statusBarService(): StatusBarService {
        if (!this._statusBarService) {
            this._statusBarService = this.injector.get(StatusBarService);
        }
        return this._statusBarService;
    }
}
